import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import * as XLSX from 'xlsx';
import * as iconv from 'iconv-lite';

import { Campaign, RequestCampaign, ResponseCampaign, ResponseCampaignCreated } from 'src/app/shared/interfaces/response-campaign.interface';
import { Filter, FilterTotal, ResponseCampaignFilterTotal } from 'src/app/shared/interfaces/response-campaign-filters.interface';
import { RequestCampaignData } from 'src/app/shared/interfaces/response-campaign-data.interface';
import { User } from 'src/app/auth/interfaces/response-login.interface';

import { AuthService } from 'src/app/auth/services/auth.service';
import { CampaignService } from 'src/app/admon/services/campaign.service';
import { UtilidadesService } from 'src/app/shared/services/utilidades.service';
import { RequestCampaignFilter } from '../../../../shared/interfaces/response-campaign-filters.interface';
import { CampaignFormat, ResponseCampaignFormats } from 'src/app/shared/interfaces/response-campaign-formats.interface';

interface FilterData {
  id: string;
  name: string;
}

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.css']
})
export class CreateCampaignComponent implements OnInit {
  loading: boolean = true;
  user: User = this.authService.user;
  login: boolean = true;
  id: number = 0;
  advertiserID: number = 0;
  advertiserName: string = '';
  CsvName: string = '';
  csvData: any[] = [];
  completed: boolean = false;
  isStep1Editable = true;
  isStep2Editable = true;
  isStep3Editable = true;
  loadFile: boolean = false;
  campaign: Campaign = null!;
  dataFiltered: FilterData[] = [];
  dataFilterData: FilterData[] = [];
  dataFilters: FilterTotal[] = [];
  dataFormats: CampaignFormat[] = [];
  campaignFormGroup = this.fb.group({
    name: ['', Validators.required],
  });
  filtersFormGroup: UntypedFormGroup = this.fb.group({
    loadFile: [this.loadFile, ],
  });
  secondFormGroup = this.fb.group({
    secondCtrl: ['', Validators.required],
  });
  isLinear: boolean = false;
  headers: string[] = [];
  edit: boolean = false;

  constructor(private authService: AuthService,
            private campaignService: CampaignService,
            private fb: FormBuilder,
            private route: ActivatedRoute,
            private router: Router,
            private utilidadesService: UtilidadesService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.authService.validarLogin();
      if(this.user.profile_id === 2) {
        this.utilidadesService.mostrarModal('Error', `Forbidden view to profile selected`);
        this.utilidadesService.goHome();
        this.return;
      }

      this.id = Number(this.utilidadesService.desencriptar(params["id"]));
      this.advertiserID = Number(this.utilidadesService.desencriptar(params["advertiser_id"]));
      this.advertiserName = params["name"];
      console.log(this.id);

      if(this.id > 0) {
        this.campaignService.getCampaignByID(Number(this.id)).subscribe({
          next: (response: ResponseCampaign) => {
            this.campaign = response.result[0];
            this.campaignFormGroup.setControl('name', new FormControl(this.campaign.name));
            this.edit = true;
            this.loading = false;
          },
          error: () => {
            this.utilidadesService.mostrarModal('Error', `Error load data.`);
          }
        });
      } else {
        this.loadFile = true;
        this.loading = false;
      }
    });
  }

  onFileChange(event: any): void {
    const file: File = event.target.files[0];
    this.loading = true;

    if (file && (file.name.endsWith('.csv')
    || file.name.endsWith('.xlsx')
    || file.name.endsWith('.xls')
    || file.name.endsWith('.tsv'))) {
      this.CsvName = file.name;
      this.readFile(file);
    } else {
      this.loading = false;
      this.utilidadesService.mostrarModal("Error", "csv or tsv or xlsx or xls extension permited")
    }
  }

  goToFilters(stepper: MatStepper): void {
    if ( this.campaignFormGroup.invalid )  {
      this.campaignFormGroup.markAllAsTouched();
      this.utilidadesService.mostrarModal('Error', `Name is required.`);
      return;
    }

    this.loading = true;
    const name: string = this.campaignFormGroup.get('name')?.value!;
    const request: RequestCampaign = {
      id: this.id,
      name: name,
      advertiser_id: this.advertiserID,
    };

    console.log(request);

    this.campaignService.addCampaign(request).subscribe({
      next: (response: ResponseCampaignCreated) => {
        this.id = response.result;

        this.campaignService.getCampaignByID(this.id).subscribe({
          next: (response: ResponseCampaign) => {
            this.campaign = response.result[0];

            if(this.edit){
              this.campaignService.getCampaignFiltersTotal(this.id).subscribe({
                next: (response: ResponseCampaignFilterTotal) => {
                  this.dataFilters = response.result;
                  console.log(response.result);

                  this.csvData[0] = [];
                  this.filtersFormGroup = this.fb.group({
                    loadFile: [this.loadFile, ],
                  });

                  response.result.forEach((filter: FilterTotal) => {
                    this.filtersFormGroup.setControl(filter.name, new FormControl('{' + filter.name.toLowerCase().trim() + '}'));
                    this.filtersFormGroup.setControl(filter.name + '_filter', new FormControl(filter.filter));
                    this.filtersFormGroup.setControl(filter.name + '_filter_size', new FormControl(filter.filter_size));
                    const newRow: FilterData = { id: filter.order.toString(), name: filter.name };
                    this.dataFiltered.push(newRow);
                    this.csvData[0].push(filter.name);
                  });

                  this.dataFiltered =  this.getDistinctByKey(this.dataFiltered, 'name');
                  console.log("dataFiltered", this.dataFiltered);

                  this.loading = false;
                  this.completed = true;
                  stepper.next();
                  this.isStep1Editable = false;
                },
                error: () => {
                  this.utilidadesService.mostrarModal('Error', `Error load data.`);
                }
              });
            } else {
              this.loading = false;
              stepper.next();
              this.isStep1Editable = false;
            }
          },
          error: () => {
            this.utilidadesService.mostrarModal('Error', `Error load data.`);
          }
        });
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load data.`);
      }
    });
  }

  goToFormats(stepper: MatStepper): void {
    if ( this.filtersFormGroup.invalid )  {
      this.campaignFormGroup.markAllAsTouched();
      this.utilidadesService.mostrarModal('Error', `A filter is required.`);
      return;
    }

    this.loading = true;
    let requestFilters: Filter[] = [];
    let fieldsData: string[][] = [];
    let idSize: number = -1;

    if (this.edit && this.CsvName === '') {
      this.headers = [];
      this.dataFilters.forEach((filter: FilterTotal) => {
        const check: boolean = this.filtersFormGroup.get(filter.name + '_filter')?.value!
        const checkSize: boolean = this.filtersFormGroup.get(filter.name + '_filter_size')?.value!

        if (checkSize) {
          idSize = filter.order;
        }

        const request: Filter = {
          id:                 filter.id,
          campaign_id:        this.campaign.id,
          name:               filter.name,
          field_html:         "",
          filter:             Number(check),
          order:              filter.order,
          filter_size:        Number(checkSize)
        };
        requestFilters.push(request);
        this.headers.push(filter.name);
      });

      const request: RequestCampaignFilter = {
        filters: requestFilters,
        edit: true,
      };

      console.log("request filters", request);

      this.campaignService.addCampaignFilters(request).subscribe({
        next: () => {
          this.getDataFormat(stepper);
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Error load filters.`);
        }
      });
    } else {
      this.headers = this.csvData[0];
      this.headers.forEach((filter: string, index: number) => {
        const check: boolean = this.filtersFormGroup.get(filter + '_filter')?.value!
        const checkSize: boolean = this.filtersFormGroup.get(filter + '_filter_size')?.value!

        if (checkSize) {
          idSize = index;
          console.log('index', idSize);
        }

        const request: Filter = {
          id:                 0,
          campaign_id:        this.campaign.id,
          name:               filter,
          field_html:         "",
          filter:             Number(check),
          order:              index + 1,
          filter_size:        Number(checkSize)
        };
        requestFilters.push(request);
      });

      const request: RequestCampaignFilter = {
        filters: requestFilters,
        edit: false,
      };

      console.log("request filters", request);
      console.log("data", this.csvData);

      this.csvData.forEach((data, index) => {
        if(index > 0) {

          if(idSize > -1) {
            let dataSplit: any;
            if(data.length > 1){
              dataSplit = data;
            } else {
              dataSplit = data[0].split(",");
            }

            this.dataFilterData.push({id: dataSplit[idSize], name: dataSplit[idSize] });
          }

          fieldsData.push(data);
        }
      });

      const uniqueFilterArray = this.dataFilterData.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.id === value.id
        ))
      );
      this.dataFilterData = uniqueFilterArray;

      console.log("request filters data size", this.dataFilterData);

      const requestDataCampaign: RequestCampaignData = {
        campaing_id: this.campaign.id,
        fields: fieldsData,
        edit: false,
      };

      console.log("requestDataCampaign", requestDataCampaign);

      this.campaignService.addCampaignFilters(request).subscribe({
        next: () => {
          this.campaignService.addCampaignData(requestDataCampaign).subscribe({
            next: () => {
              this.getDataFormat(stepper);
            },
            error: () => {
              this.utilidadesService.mostrarModal('Error', `Error load data.`);
            }
          });
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Error load filters.`);
        }
      });
    }
  }

  goToFinish(stepper: MatStepper): void {
    stepper.next();
    this.isStep3Editable = false;
  }

  getDataFormat(stepper: MatStepper): void {
    if(this.edit){
      this.campaignService.getCampaignFormats(this.id.toString()).subscribe({
        next: (response: ResponseCampaignFormats) => {
          this.dataFormats = response.result;
          console.log(response.result);

          if(this.dataFormats.length === 0) {
            if(this.dataFilterData.length > 0) {
              this.dataFilterData.forEach((resp, index) => {
                this.dataFormats.push({
                  id: 0,
                  name: resp.name,
                  html: '',
                  width: '',
                  height: '',
                  path: '',
                  status: ''
                });
              });
            }
          }

          console.log("this.dataFormats", this.dataFormats);

          this.loading = false;
          this.completed = true;
          stepper.next();
          this.isStep1Editable = false;
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Error load data.`);
        }
      });
    } else {

      if(this.dataFilterData.length > 0) {
        this.dataFilterData.forEach((resp, index) => {
          this.dataFormats.push({
            id: 0,
            name: resp.name,
            html: '',
            width: '',
            height: '',
            path: '',
            status: ''
          });
        });
      }

      this.loading = false;
      stepper.next();
      this.isStep2Editable = false;
    }
  }

  addPanel(): void {
    this.dataFormats.push({
      id: 0,
      name: '',
      html: '',
      width: '',
      height: '',
      path: '',
      status: ''
    });
  }

  validateField(form: FormGroup, campo: string): boolean | null{
    return this.utilidadesService.campoEsValido(form, campo);
  }

  readFile(file: File): void {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const arrayBuffer = e.target.result;

      const uint8Array = new Uint8Array(arrayBuffer);
      const textDecoder = new TextDecoder('utf-8');
      const utf8Content = textDecoder.decode(uint8Array);
      const workBook = XLSX.read(utf8Content, { type: 'string' });
      const sheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[sheetName];
      const csvData: string[] = XLSX.utils.sheet_to_json(workSheet, {
          header: 1,
          raw: false,
      });
      let csvDataHead: any[] = [];

      this.filtersFormGroup = this.fb.group({
        loadFile: [this.loadFile, ],
      });

      csvDataHead = csvData;
      csvDataHead[0].forEach((col: string) => {
        this.filtersFormGroup.setControl(col, new FormControl('{' + col.toLowerCase().trim() + '}'));
        this.filtersFormGroup.setControl(col + '_filter', new FormControl(''));
        this.filtersFormGroup.setControl(col + '_filter_size', new FormControl(''));
      });

      csvDataHead.forEach((row: string[], index: number) => {
        if(index > 0){
          row.forEach((value: string, index: number) => {
            const newRow: FilterData = { id: csvDataHead[0][index], name: value.toString() };
            this.dataFiltered.push(newRow);
          });
        }
      });

      this.dataFiltered =  this.getDistinctByKey(this.dataFiltered, 'name');
      console.log("dataFiltered", this.dataFiltered);

      this.csvData = csvData;

      console.log('Parsed CSV: ', this.csvData);
      this.loading = false;
      this.completed = true;
    };

    reader.readAsArrayBuffer(file);
  }

  checkLoadFile(): void {
    this.loadFile = this.loadFile ? false : true;
  }

  getDistinctByKey(array: { id: string, name: string }[], key: string) {
    const uniqueValues = new Set();
    return array.filter((item: any) => {
      const isPresentInSet = uniqueValues.has(item[key]);
      uniqueValues.add(item[key]);
      return !isPresentInSet;
    });
  }

  return(): void{
    this.router.navigate(['/', 'campaigns'], {
      queryParams: {
        advertiser_id: this.utilidadesService.encriptar(this.advertiserID.toString()),
        name: this.advertiserName
      }
    });
  }

  preview(id: number): void {
    const idEncr = this.utilidadesService.encriptar(id.toString());
    window.open(`/preview?id=${ idEncr }`, '_blank');
  }
}
