import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { Filter } from 'src/app/shared/interfaces/response-campaign-filters.interface';
import { ResponseFormats, Formats } from 'src/app/shared/interfaces/response-formats.interface';
import { User } from 'src/app/auth/interfaces/response-login.interface';

import { AdmonService } from '../../../services/admon.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CampaignService } from 'src/app/admon/services/campaign.service';
import { DataService } from '../../../services/data.service';
import { UtilidadesService } from 'src/app/shared/services/utilidades.service';
import { CampaignFormat, RequestCampaignFormat, ResponseCampaignFormats } from 'src/app/shared/interfaces/response-campaign-formats.interface';
import { Campaign } from 'src/app/shared/interfaces/response-campaign.interface';
import { CampaignDeta, RequestCampaignDeta, ResponseCampaignDeta } from 'src/app/shared/interfaces/response-campaign-deta.interface';
import { CampaignDetaHtml, RequestCampaignDetaHtml, ResponseCampaignDetaHtml } from 'src/app/shared/interfaces/response-campaign-deta-html.interface';

@Component({
  selector: 'app-create-format',
  templateUrl: './create-format.component.html',
  styleUrls: ['./create-format.component.css']
})
export class CreateFormatComponent implements OnInit, OnChanges {

  @Input() headers: string[] = [];
  @Input() campaign: Campaign = null!;
  @Input() format: CampaignFormat = null!;

  loading: boolean = false;
  login: boolean = true;
  user: User = this.authService.user;
  fileName: string = '';
  htmlContent: string = '';
  dynamicData: any;
  fileZip: any;
  completed: boolean = false;
  formats: Formats[] = [];
  edit: boolean = false;
  loadFile: boolean = false;
  formatsDeta: CampaignDeta[] = [];
  formatsDetaHtml: CampaignDetaHtml[] = [];
  formatFormGroup: UntypedFormGroup = this.fb.group({
    formatId: ['', Validators.required],
    loadFile: [this.loadFile, ],
  });

  constructor(private fb: UntypedFormBuilder,
              private admonService: AdmonService,
              private authService: AuthService,
              private campaignService: CampaignService,
              private dataService: DataService,
              private utilidadesService: UtilidadesService) { }

  ngOnInit(): void {
    this.loading = true;
    this.authService.validarLogin();
    this.admonService.getFormats().subscribe({
      next: (response: ResponseFormats) => {
        this.formats = response.result;

        if (this.format.id > 0) {
          this.formatFormGroup.setControl(`formatId`, new FormControl(this.format.format_id));
          this.getDetaFormat();
          this.edit = true;
          this.completed = true;
        } else {
          this.loading = false;
          this.loadFile = true;
        }
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load data.`);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['headers']) {
      this.headers = changes['headers'].currentValue;
      this.headers.forEach((col: string) => {
        this.formatFormGroup.setControl('filter_' + col.toLowerCase().trim(), new FormControl(''));
      });
    }
  }

  onFileChangeHtml(event: any): void {
    const file = event.target.files[0];
    this.fileZip = file;
    this.loading = true;

    if (file && file.name.endsWith('.zip')) {
      this.fileName = file.name;
      const reader = new FileReader();

      reader.onload = (e) => {
        const arrayBuffer: ArrayBuffer = reader.result as ArrayBuffer;
        JSZip.loadAsync(arrayBuffer).then((zip) => {
          zip.file("index.html")?.async("string").then((content) => {
            this.htmlContent = content;
            this.dynamicData = this.dataService.getColumns(this.htmlContent);
            this.headers.forEach(field_html => {
              this.formatFormGroup.setControl(`filter_${ field_html.toLowerCase().trim() }`, new FormControl(field_html));
            });
            this.completed = true;
            this.edit = false;
            this.loading = false;
          });
        });
      };

      reader.readAsArrayBuffer(file);
    } else {
      this.loading = false;
      this.utilidadesService.mostrarModal("Error", "Invalid zip")
    }
  }

  create(): void {
    this.loading = true;
    const requestFormat: RequestCampaignFormat = {
      id:           this.format.id > 0 ? this.format.id : 0,
      campaign_id:  this.campaign.id,
      format_id:    this.formatFormGroup.get('formatId')?.value!,
      path:         this.format.id > 0 ? this.format.path : "",
      status:       "A"
    }

    console.log(requestFormat);

    this.campaignService.addCampaignFormat(requestFormat).subscribe({
      next: (format: ResponseCampaignFormats) => {
        console.log("format created", format);
        if (this.edit) {
          this.addDetaByData(format.result[0]);
        } else {
          this.addDetaByFile(format.result[0]);
        }
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load format.`);
      }
    });
  }

  getDetaFormat(): void {
    this.campaignService.getCampaignDeta(this.format.id).subscribe({
      next: (responseDeta: ResponseCampaignDeta) => {
        console.log("responseDeta", responseDeta);
        this.formatsDeta = responseDeta.result;

        this.campaignService.getCampaignDetaHtml(this.format.id).subscribe({
          next: (responseDetaHtml: ResponseCampaignDetaHtml) => {
            console.log("responseDetaHtml", responseDetaHtml);
            this.formatsDetaHtml = responseDetaHtml.result;
            this.dynamicData = [];
            responseDetaHtml.result.forEach(data => this.dynamicData.push(data.field_html));

            this.headers.forEach(field_html => {
              this.formatFormGroup.setControl(`filter_${ field_html.toLowerCase().trim() }`, new FormControl(field_html));
            });

            this.formatsDeta.forEach((element: CampaignDeta) => {
              this.formatFormGroup.setControl(`filter_${ element.name.toLowerCase().trim() }`, new FormControl(element.field_html));
            });

            this.loading = false;
        },
        error: () => {
          this.utilidadesService.mostrarModal('Error', `Error load data format.`);
          this.loading = false;
        }});
    },
    error: () => {
      this.utilidadesService.mostrarModal('Error', `Error load data format.`);
      this.loading = false;
    }});
  }

  addDetaByFile(format: CampaignFormat): void {
    let requestDeta: Filter[] = [];
    this.campaignService.loadFileFormatCampaign(this.fileZip, this.campaign.path, format.path).subscribe({
      next: () => {
        this.headers.forEach((filter: string, index: number) => {
          const field_html: string = this.formatFormGroup.get('filter_' + filter.toLowerCase().trim())?.value!
          const request: Filter = {
            id:                 0,
            campaign_id:        format.id,
            name:               filter,
            field_html:         field_html,
            filter:             0,
            order:              index + 1,
            filter_size:        0
          };

          if (field_html != '') {
            requestDeta.push(request);
          }
        });

        const request: RequestCampaignDeta = {
          fields: requestDeta,
          edit: false,
        };

        console.log("requestDeta", request);

        this.campaignService.addCampaignDeta(request).subscribe({
          next: () => {
            let requestDetaHtml: Filter[] = [];
            console.log("dynamicData", this.dynamicData);
            this.dynamicData.forEach((element: string) => {
              if(element != ""){
                const dataHtml: Filter = {
                  id:                 0,
                  campaign_id:        format.id,
                  field_html:         element,
                  name:               '',
                  filter:             0,
                  order:              0,
                  filter_size:        0
                };
                requestDetaHtml.push(dataHtml);
              }
            });

            const requestHtml: RequestCampaignDetaHtml = {
              fields: requestDetaHtml,
              edit: false,
            };

            console.log("requestDetaHtml", requestHtml);

            this.campaignService.addCampaignDetaHtml(requestHtml).subscribe({
              next: () => {
                this.loading = false;
                this.completed = true;
                this.utilidadesService.mostrarModal('Info', `Load succesfully.`);
              },
              error: () => {
                this.utilidadesService.mostrarModal('Error', `Error load data.`);
              }
            });
          },
          error: () => {
            this.utilidadesService.mostrarModal('Error', `Error load data.`);
          }
        });
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load file.`);
      }
    });
  }

  addDetaByData(format: CampaignFormat): void {
    let requestDeta: Filter[] = [];
    this.headers.forEach((filter: string, index: number) => {
      const field_html: string = this.formatFormGroup.get('filter_' + filter.toLowerCase().trim())?.value!
      const request: Filter = {
        id:                 0,
        campaign_id:        this.format.id,
        name:               filter,
        field_html:         field_html,
        filter:             0,
        order:              index + 1,
        filter_size:        0
      };

      if (field_html != '') {
        requestDeta.push(request);
      }
    });

    const request: RequestCampaignDeta = {
      fields: requestDeta,
      edit: false,
    };

    console.log("requestDeta", request);

    this.campaignService.addCampaignDeta(request).subscribe({
      next: () => {
        let requestDetaHtml: Filter[] = [];
        console.log("dynamicData", this.dynamicData);
        this.dynamicData.forEach((element: string) => {
          if(element != ""){
            const dataHtml: Filter = {
              id:                 0,
              campaign_id:        format.id,
              field_html:         element,
              name:               '',
              filter:             0,
              order:              0,
              filter_size:        0
            };
            requestDetaHtml.push(dataHtml);
          }
        });

        const requestHtml: RequestCampaignDetaHtml = {
          fields: requestDetaHtml,
          edit: false,
        };

        console.log("requestDetaHtml", requestHtml);

        this.campaignService.addCampaignDetaHtml(requestHtml).subscribe({
          next: () => {
            this.loading = false;
            this.completed = true;
            this.utilidadesService.mostrarModal('Info', `Load succesfully.`);
          },
          error: () => {
            this.utilidadesService.mostrarModal('Error', `Error load data.`);
          }
        });
      },
      error: () => {
        this.utilidadesService.mostrarModal('Error', `Error load data.`);
      }
    });
  }

  checkLoadFile(): void {
    this.loadFile = this.loadFile ? false : true;
  }

  async saveHtmlFileVariation(head: string[], row: string[]): Promise<void> {

    let htmlCopy: string = this.htmlContent;
    head.forEach((element: string, index: number) => {
      //console.log(this.formularioAdicionar.get(element)?.value, row[index]);
      htmlCopy = htmlCopy.replace(this.formatFormGroup.get(element)?.value, row[index])
    });

    const files = [
      { name: 'index.html', content: new Blob([htmlCopy], { type: 'text/html;charset=utf-8' }) },
      { name: 'arrow.svg', content: await this.urlToBlob('/assets/template/template_base/arrow.svg') },
      { name: 'backup.jpg', content: await this.urlToBlob('/assets/template/template_base/backup.jpg') },
      { name: 'Img_300x600.jpg', content: await this.urlToBlob('/assets/template/template_base/Img_300x600.jpg') },
      { name: 'instagram_icons.svg', content: await this.urlToBlob('/assets/template/template_base/instagram_icons.svg') },
      { name: 'instagram_logo.png', content: await this.urlToBlob('/assets/template/template_base/instagram_logo.png') },
      { name: 'Logo.png', content: await this.urlToBlob('/assets/template/template_base/Logo.png') },
      // Add more files as needed
    ];
    await this.createZip(files, row[1]);
  }

  async createZip(files: { name: string, content: Blob }[], fileName: string): Promise<void> {
    const zip = new JSZip();
    files.forEach(file => {
      zip.file(file.name, file.content);
    });
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, fileName + '.zip');
  }

  async urlToBlob(fileUrl: string): Promise<Blob> {
    const response = await fetch(fileUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.statusText}`);
    }
    return await response.blob();
  }
}
